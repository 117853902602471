<template>
  <div class="streaming-finished pa-2 d-flex justify-center align-center">
    <v-chip color="primary" text-color="white">
      <v-avatar left>
        <v-icon>mdi-checkbox-marked-circle</v-icon>
      </v-avatar>
      <span class="px-2" v-text="$t('streaming.finished')" />
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'StramingToStart',
}
</script>
<style lang="scss">
.streaming-finished {
  height: 100%;
}
</style>
